angular.module('app').component('requestPassword', {
	templateUrl: 'app/components/request-password/request-password.html',
	controller: function(Auth, LxNotificationService, $state) {
		this.requestPassword = (email) => {
			Auth.resetPassword({ email: email, ttl: 1209600, isRequestingPassword: true}).$promise.then(result => {
				LxNotificationService.success("Pedido enviado com sucesso, por favor consulte o email.");
				$state.go('auth', {});
			}).catch(error => {
				LxNotificationService.error("Ocorreu um erro no pedido, por favor tente novamente.");
			});
		}
	}
});