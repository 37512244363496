angular.module('app').component('passwordReset', {
	templateUrl: 'app/components/password-reset/password-reset.html',
	controller: function(Auth, $stateParams, $state, LxNotificationService, crypto, $scope) {
		var $ctrl = this;

		if (!$stateParams.token)
			$state.go('auth');

		function hasUpperCase(str) {
			return (/[A-Z]/.test(str));
		}

		function hasNumber(str) {
			return /\d/.test(str);
		}

		function validatePassword(password) {
			if (password.length >= 10 && hasUpperCase(password) && hasNumber(password)) {
				return true;
			} else {
				return false;
			}
		}
		$ctrl.getKey = function(password) {
			return new Promise(function(resolve, reject) {
				Auth.getKey({ access_token: $stateParams.token }, {}).$promise.then(result => {
					return crypto.decryptText(result.accessKey, $stateParams.accessKey.split("-")[0], $stateParams.accessKey.split("-")[1])
				}).then(key => {
					return crypto.encryptTextWithText(key, password)
				}).then(result => resolve(result))
					.catch(err => reject(err))
			})
		}

		$ctrl.creatUser = function(key, iv) {
			var createdUser = { username: $ctrl.username, password: $ctrl.password, confirmation: $ctrl.passwordConfirm }
			if (key) {
				createdUser.key = key
				createdUser.iv = iv
			}
			Auth.resetConfirm({ access_token: $stateParams.token }, createdUser).$promise.then(function(result) {
				LxNotificationService.success("Registo bem sucedido!");
				$state.go("auth");
			}).catch(function(error) {
				LxNotificationService.error(error.data.error.message);
			}).finally(() => {
				$ctrl.loading = false
			});
		}

		$ctrl.register = function() {

			if ($ctrl.passwordConfirm !== $ctrl.passwordConfirm) return LxNotificationService.error("As passwords devem ser iguais")

			if (!validatePassword($ctrl.password)) return LxNotificationService.error('A sua password deverá ter pelo menos 10 caracteres e conter pelo menos 1 número e 1 letra maiúscula.');

			$ctrl.loading = true

			if ($stateParams.accessKey) {
				$ctrl.getKey($ctrl.password).then(({ key, iv }) => {
					$ctrl.creatUser(key, iv)
				})
			} else {
				$ctrl.creatUser()
			}
		}
	}
});