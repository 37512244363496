angular.module('app').component('appointmentAnswer', {
	templateUrl: 'app/components/appointment-answer/appointment-answer.html',
	controller: function(Appointment, $stateParams, $window) {
		var $ctrl = this;

		$ctrl.answer = $stateParams.answer;
		if ($ctrl.answer === "schedule") {
			$window.location.href = "/ohs-patient/#!/scheduling";
		} else if ($ctrl.answer === "yes") {
			Appointment.acceptAppointment({ key: $stateParams.key }).$promise.then(function(result) {
				$ctrl.answered = true;
			})
		} else if ($ctrl.answer === "no") {
			Appointment.rejectAppointment({ key: $stateParams.key }).$promise.then(function(result) {
				$ctrl.answered = true;
			})
		}
	}
});