angular.module('app', ['ngResource', 'ui.router', 'lbServices', 'angularMoment', 'lumx', "pageslide-directive"])
	.config(function($stateProvider, $urlRouterProvider) {
		$stateProvider.state({
			name: 'auth',
			url: '/auth?redirect',
			component: 'auth',
			resolve: {}
		}).state({
			name: 'profile',
			url: '/profile',
			component: 'profile',
			resolve: {},
			requireLogin: true
		}).state({
			name: 'password-reset',
			url: '/password-reset?token&accessKey',
			component: 'passwordReset',
			resolve: {}
		}).state({
			name: 'appointment-answer',
			url: '/appointment-answer?answer&key',
			component: 'appointmentAnswer'
		}).state({
			name: 'request-password',
			url: '/request-password',
			component: 'requestPassword'
		})

		$urlRouterProvider.otherwise('/auth');
	});