angular.module('app').component('auth', {
	templateUrl: 'app/components/auth/auth.html',
	bindings: { forbbidenBrowser: "<"},
	controller: function($state, Auth, LxNotificationService, $window, $stateParams) {
		var $ctrl = this;

		$ctrl.login = function() {

			Auth.login({ username: $ctrl.username, password: $ctrl.password }).$promise
				.then(function(result) {

					if(result.user){
						Tawk_API.setAttributes({
							id: result.user.id,
							email: result.user.email,
							name: result.user.name,
							role: result.user.role,
							username: result.user.username,
						}, function(error){});
					}
					
					
					LxNotificationService.success("Login bem sucedido");

					var location = "/ohs-operational";
					if (result.user.role === "admin")
						location = "/ohs-admin";
					if (result.user.role === "doctor" || result.user.role === "nurse")
						location = "/ohs-doctor";
					if (result.user.role === "operational")
						location = "/ohs-operational";
					if (result.user.role === "financial")
						location = "/ohs-financial";
					if (result.user.role === "hst")
						location = "/ohs-hst";
					if (result.user.role === "patient")
						location = "/ohs-patient";
					if (result.user.role === "external")
						location = "/ohs-external";

					if ($stateParams.redirect)
						location += "/#!" + $stateParams.redirect;

					$window.location.href = location;
				}).catch(function(err) {
					console.log(err)
					LxNotificationService.error("Erro na password ou no nome de utilizador!");
				})
		}

		$ctrl.logout = function() {
			Auth.logout().$promise.then(function(result) {
				console.log(result);
			}).catch(function(error) {
				console.log(error)
			})
		}
	}
});